import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Video_banner from "../images/cb_goods/video.mp4"
import Slider from "react-slick"
import Step1 from "../images/cb_goods/sec1.jpg"
import Step2 from "../images/cb_goods/sec2.jpg"
import Step3 from "../images/cb_goods/sec3.jpg"
import Health from "../images/cb_goods/health.svg"
import Biohacking from "../images/cb_goods/Biohacking.svg"
import Farms from "../images/cb_goods/farm.svg"
import Zero_waste from "../images/cb_goods/zero_waste.svg"
import Vegan from "../images/cb_goods/vegan.svg"
import Energy from "../images/cb_goods/clean_energy.svg"
import Ethical from "../images/cb_goods/ethical.svg"
import Startup from "../images/cb_goods/startup.svg"
import Why_cb from "../images/cb_goods/why_cb.png"
import Why_cb1 from "../images/cb_goods/mobi.png"
import Dots from "../images/cb_goods/dots.png"
import Feather_icon from "../images/cb_goods/feather-external-link.png"
import Badabon_logo from "../images/cb_goods/badabon_logo.png"
import Atg_logo from "../images/cb_goods/allthatgrows_logo.png"
import Ansel_logo from "../images/cb_goods/ansel.png"
import Dao_logo from "../images/cb_goods/dao_logos.jpg"
import Learningrx from "../images/cb_goods/learning.png"
import Cfg_logo from "../images/cb_goods/cfglogo.svg"


import BCORPs from "../images/cb_goods/BCORPs.png"
import Cradle from "../images/cb_goods/Cradle.png"
import ClimateNeutral from "../images/cb_goods/ClimateNeutral.png"
import LeapingBunny from "../images/cb_goods/LeapingBunny.png"
import EPA from "../images/cb_goods/EPA.png"
import EWG from "../images/cb_goods/EWG.png"
import MadeSafe from "../images/cb_goods/MadeSafe.png"
import B1G1 from "../images/cb_goods/B1G1.png"
import USDA from "../images/cb_goods/USDA.png"
import fortheplanet from "../images/cb_goods/1%fortheplanet.png"


import Scroll_top_img from "../images/scroll-arrow.png"
import {Helmet} from "react-helmet"

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'

const Mobile_slider = {
   slidesToShow: 1,
   slidesToScroll: 1,
   dots: true,
   centerMode: false,
   infinite: true,
   pauseOnHover: true,
   accessibility: false,
   focusOnSelect: true,
   autoplay: false,
}; 

export default class AsNavFor extends Component {
   constructor(props) {
      super(props);
      this.state = {
         nav1: null,
         nav2: null,
         url: '',
         tabNumber: 0, // Set the initial tab number
      };
   }
   
   componentDidMount() {
      this.setState({
         nav1: this.slider1,
         nav2: this.slider2,
         url: document.location.href
      });
   }

   // Function to update the tab number
   setTabNumber = (newTabNumber) => {
      this.setState({ tabNumber: newTabNumber });
   };

   render() {
      const { nav1 } = this.state;
      const { nav2 } = this.state;

      const nav = {
         asNavFor: nav1,
         ref: slider => {
            this.slider2 = slider;
         },
         slidesToShow: 5,
         slidesToScroll: 5,
         dots: true,
         centerMode: false,
         infinite: true,
         pauseOnHover: true,
         pauseOnHover: true,
         accessibility: false,
         focusOnSelect: true,
         autoplay: false,
     
      };

      const view = {
         asNavFor: nav2,
         ref: slider => {
            this.slider1 = slider;
         },
         slidesToShow: 1,
         slidesToScroll: 5,
         arrows: false,
         fade: true,
         draggable: false,
         infinite: true,
         dots: false,
 
      };

      const tabContent = [
         <div className={this.state.tabNumber === 0 ? "tabcontent" : ""}>
            <h5>BCORPs</h5>
           <p>B Corp Certification is a designation that a business is meeting high standards of verified performance, accountability, and transparency on factors from employee benefits and charitable giving to supply chain practices and input materials.
"We won't stop until all business is a force for good."</p>
         </div>,
         <div className={this.state.tabNumber === 1 ? "tabcontent" : ""}>
            <h5>Cradle 2 Cradle</h5>
           <p>Cradle to Cradle Certified® is the leading multi-attribute standard used globally across industries by designers, brands and manufacturers for designing and making products that enable a healthy, equitable and sustainable future.</p>
         </div>,
         <div className={this.state.tabNumber === 2 ? "tabcontent" : ""}>
            <h5>Climate Neutral
</h5>
           <p>Climate Neutral is a nonprofit organization working to eliminate carbon emissions.</p>
         </div>,
         <div className={this.state.tabNumber === 3 ? "tabcontent" : ""}>
            <h5>Leaping Bunny
</h5>
           <p>Since 1996, the Leaping Bunny Program, operated by the Coalition for Consumer Information on Cosmetics in the US and Canada, has been connecting compassionate consumers to cruelty-free companies under its Corporate Standard of Compassion for Animals. Companies must pledge to end animal testing at all stages of product development in addition to recommitting to the program annually and being open to third party audits.</p>
         </div>,
         <div className={this.state.tabNumber === 4 ? "tabcontent" : ""}>
            <h5>EPA Safer Choice
</h5>
           <p>Safer Choice helps consumers, businesses, and purchasers find products that perform and contain ingredients that are safer for human health and the environment. Safer Choice is an EPA Pollution Prevention (P2) program, which includes practices that reduce, eliminate, or prevent pollution at its source, such as using safer ingredients in products.
</p>
         </div>,
         <div className={this.state.tabNumber === 5 ? "tabcontent" : ""}>
            <h5>EWG verified
</h5>
           <p>EWG VERIFIED® recognizes products that meet EWG's strictest standards for your health. This means none of EWG's chemicals of concern. 
"Our ultimate goal is for all products on the market to be free from harmful chemicals, so that everyone's purchasing decisions can finally be stress free."</p>
         </div>,
         <div className={this.state.tabNumber === 6 ? "tabcontent" : ""}>
            <h5>Made Safe
</h5>
           <p>Products seeking the MADE SAFE (Made With Safe Ingredients™) seal are screened to ensure that over 6,500 Banned/Restricted List substances have been avoided or constrained, thereby eliminating the worst hazards commonly found in products used in our homes and daily routines. 
"This is the first step in our unique Ecosystem Approach to screening, which is designed to protect people and all the resources we rely on for life."</p>
         </div>,
         <div className={this.state.tabNumber === 7 ? "tabcontent" : ""}>
            <h5>B1G1
</h5>
           <p>B1G1 operates on a subscription basis with a transaction-based charity giving model designed for small and medium enterprises (SMEs) around the world. It presents itself as an effective way to contribute to charitable organizations.Through B1G1, business members can donate money to B1G1's vetted charity partners, called "B1G1 Worthy Causes".</p>
         </div>,
         <div className={this.state.tabNumber === 8 ? "tabcontent" : ""}>
            <h5>USDA BioPreferred
</h5>
           <p>The BioPreferred Program is a USDA-led initiative that aims to assist in the development and expansion of markets for biobased products. The program was created by the 2002 Farm Bill (legislation), and expanded as part of the 2014 Farm Bill. The increased development, purchase, and use of biobased products will reduce our Nation's reliance on petroleum, and increase the use of renewable agricultural materials.</p>
         </div>,
         <div className={this.state.tabNumber === 9 ? "tabcontent" : ""}>
            <h5>1% for the planet
</h5>
           <p>One Percent for the Planet is an international organization whose members contribute at least one percent of their annual revenue to environmental causes to protect the environment.
</p>
         </div>,
      
         // Add more tab content divs here for other tabs
       ];
   
   return (
      <Layout>
            <Helmet>  
            <title>Ecommerce Agency helping B Corps & Brands use Business as a Force for Good</title>
            <meta name="description" content="We leverage eCommerce for Purpose. If you're a BCorp, or your initiatives resonate with health, zero waste, ethical sourcing, & planet-positive values, let's work together to amplify your online presence."/>
               <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                     <link rel="canonical" href={this.state.url} />
                     <meta property="og:site_name" content="CueForGood" />
                     <meta property="fb:app_id" content="289086684439915" />
                     <meta property="og:url" content={this.state.url} />
                     <meta property="og:title" content="Ecommerce Agency helping B Corps & Brands use Business as a Force for Good" />
                     <meta property="og:description" content="We leverage eCommerce for Purpose. If you're a BCorp, or your initiatives resonate with health, zero waste, ethical sourcing, & planet-positive values, let's work together to amplify your online presence." />
                     

                     <meta name="twitter:card" content="summary_large_image" />
                     <meta name="twitter:description" content="We leverage eCommerce for Purpose. If you're a BCorp, or your initiatives resonate with health, zero waste, ethical sourcing, & planet-positive values, let's work together to amplify your online presence." />
                     <meta name="twitter:title" content="Ecommerce Agency helping B Corps & Brands use Business as a Force for Good" />
                     <meta name="twitter:site" content="@CueForGood" />
                  
            </Helmet>
         <div className=" cb_good_section ">
            <div className="banner_cb">
               <div className="video-sec">
                  <video width="100%" autoPlay="autoPlay" loop="loop" muted>
                     <source src={Video_banner} type="video/mp4" />
                  </video>
               </div>
               <div className="banner-content">
                  <h1>Cue <span>for</span></h1>
                  <h2 id="suf">Good</h2>
                  <h5>Leveraging eCommerce for purpose</h5>

                  <span className="scroll-down">
                     <a href="#entity">
                        <span className="circle">
                           <strong></strong>
                        </span>
                        Scroll down
                     </a>
                  </span>
               </div>

            </div>
            <div className="tab-content-cbgood">
            <div className="container">
               <h2>eCommerce Agency for brands certified as:</h2>
               <div className="tab-contentInner">
               <div class="tab">
                        <div className={`tab-images ${this.state.tabNumber === 0 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(0)}>
                           <div className="tb-img">
                              <img src={BCORPs} /></div>
                           <h4>BCORPs</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 1 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(1)}>
                        <div className="tb-img">
                           <img src={Cradle} /></div>
                           <h4>Cradle 2 Cradle</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 2 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(2)}>
                        <div className="tb-img">
                           <img src={ClimateNeutral} /></div>
                           <h4>Climate Neutral</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 3 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(3)}>
                        <div className="tb-img">
                           
                           <img src={LeapingBunny} /></div>
                           <h4>Leaping Bunny</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 4 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(4)}>
                        <div className="tb-img">

                           <img src={EPA} /></div>
                           <h4>EPA Safer Choice</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 5 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(5)}>
                        <div className="tb-img">

                           <img src={EWG} /></div>
                           <h4>EWG verified</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 6 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(6)}>
                        <div className="tb-img">

                           <img src={MadeSafe} /></div>
                           <h4>Made Safe</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 7 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(7)}>
                        <div className="tb-img">

                           <img src={B1G1} /></div>
                           <h4>B1G1</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 8 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(8)}>
                        <div className="tb-img">

                           <img src={USDA} /></div>
                           <h4>USDA BioPreferred</h4>
                        </div>
                        <div className={`tab-images ${this.state.tabNumber === 9 ? 'borderit' : ""}`} onClick={() => this.setTabNumber(9)}>
                        <div className="tb-img">

                           <img src={fortheplanet} /></div>
                           <h4>1% for the planet</h4>
                        </div>
                  </div>
                  <div className="tab-text-outer">
                     {tabContent[this.state.tabNumber]}
                  </div>  
               </div>
            </div>
            </div>
            <div className="positive-impact" id="entity">
               <div className="container">
                  <ScrollAnimation animateIn="slideInUp" opacity="0" animateOnce="true">
                     <h3>
                        CueForGood is driven by the ethos of 'service' & making a positive
                        impact in this world.
                     </h3>
                  </ScrollAnimation>
                  <h4>
                     As part of the 'Cue For Good' initiative, we consciously work
                     with brands, companies, eCommerce businesses, start-ups as well as
                     purpose-driven organizations that care about making more than just
                     mere profits.
                  </h4>
               </div>
            </div>


            <div className="cb_category">
               <div className="container">
                  <h2 className="heading-cb">
                  The entities we serve as part of Cue For Good, fall under three broad categories:
                           </h2>
                  <div className="category-inner">
                  <div className="cat-sec sec1">
                     <div className="wd-50 ">
                        <img src={Step1}
                           alt="CueForGood for eCommerces offering products better for the Planet's Health"
                              className="img-responsive" />
                        
                     </div>
                     <div className="wd-50 pd_lf-rgt-50">
                     <span>01</span>
                        <p>
                        eCommerce Companies (established or startups) that create or sell products which are better for the health of our planet and all of its inhabitants.
                                 </p>
                     </div>
                  </div>
                  <div className="cat-sec cat-right">
                     <div className="wd-50 right-pull">
                        <img src={Step2} alt="CueForGood for Organizations that make World Better"
                              className="img-responsive" />
                     </div>
                     <div className="wd-50 pd_lf-rgt-50">
                        <span>02</span>
                        <p>
                        Organizations that have services or initiatives that have a direct meaningful impact on making our world better.</p>
                     </div>

                  </div>
                  <div className="cat-sec sec3">
                     <div className="wd-50 ">
                        <img src={Step3} alt="CueForGood for Not-Just-for-Profit Businesses"
                              className="img-responsive" />
                     </div>
                     <div className="wd-50 pd_lf-rgt-50">
                        <span>03</span>
                        <p>Purpose-driven/Not-just-for-profit Businesses.</p>
                     </div>
                  </div>
                  </div>
               </div>
            </div>

            <div className="cb-offer-products">
               <div className="container">
                  <h2 className="heading-cb">
                     We aim to be the wind in the sails of socially-responsible companies
                     that offer products and services in any of the following industries:
                           </h2>
                  <ul>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInLeft" opacity="0" animateOnce="true">
                           <img src={Health} alt="health Wellness nutrition" className="ft-l" />
                           </ScrollAnimation>
                        </figure>
                        <p>Health, Wellness & Nutrition</p>
                     </li>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInUp" opacity="0" animateOnce="true">
                           <img src={Biohacking} alt="Biohacking" className="ft-up" />
                        </ScrollAnimation>
                        </figure>
                        <p>Biohacking</p>
                     </li>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInUp" opacity="0" animateOnce="true">
                           <img src={Farms} alt="Farm to Table" className="ft-up" />
                        </ScrollAnimation>
                        </figure>
                        <p>Farm to Table</p>
                     </li>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInRight" opacity="0" animateOnce="true">
                           <img src={Zero_waste} alt="Zero Waste" className="ft-r" />
                        </ScrollAnimation>
                        </figure>
                        <p>Zero Waste</p>
                     </li>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInLeft" opacity="0" animateOnce="true"> 
                           <img src={Vegan} alt="Vegan & Cruelty-Free" className="ft-l" />
                        </ScrollAnimation>
                        </figure>
                        <p>Vegan & Cruelty-Free</p>
                     </li>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInUp" opacity="0" animateOnce="true">
                           <img src={Energy} alt="Clean Energy & Environment" className="ft-up" />
                           </ScrollAnimation>
                        </figure>
                        <p>Clean Energy & Environment</p>
                     </li>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInUp" opacity="0" animateOnce="true">
                           <img src={Ethical} alt="Ethical & Sustainably sourced products/services"
                              className="ft-up" />
                           </ScrollAnimation>
                        </figure>
                        <p>Ethical & Sustainably sourced products/services</p>
                     </li>
                     <li>
                        <figure>
                           <ScrollAnimation animateIn="slideInRight" opacity="0" animateOnce="true">
                           <img src={Startup}
                              alt="Startups with innovative solutions to world  problems"
                              className="ft-r" />
                           </ScrollAnimation>
                        </figure>
                        <p>Startups with innovative solutions to world problems</p>
                     </li>
                  </ul>
                  <div className="contactus-area">
                     <div className="left-cont">
                        <h2 className="heading-cb">Make us part of the impact</h2>
                        <p>Please <Link to="/contact/" className="a-simple">share details</Link> of your
                                       story & project requirements</p>
                     </div>
                     <div className="right-cont">
                        <Link to="/contact/" className="contactus-btn">Contact us</Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="why-cb">
               <div className="container">
                  <div className="wd-50 why_cb_left">
                     <h2 className="heading-cb">Why CueForGood?</h2>
                     <h6>Aligning competence with purpose</h6>
                     <p>
                        We’ve been doing eCommerce for a while now. Since 2005 we have had
                        extensive experience working with small and medium-sized
                        businesses/organizations, focusing on their eCommerce design,
                        development, and marketing needs.
                              </p>
                     <p>
                        Looking inwards, we feel that our association with such brands/causes will help us leave a positive impact on our planet, albeit
                        vicariously. It’s that warm fuzzy feeling we get when we know that
                        CueForGood is behind these great teams which are making a positive
                        difference in our world.
                              </p>
                     <p>
                        We firmly believe that when Competence and Purpose align, great
                        things can be accomplished.
                              </p>
                  </div>
                  <div className="wd-50 why_cb_right">
                     <div className="cb_right_inn">
                        <span className="dots-frame"><img src={Dots} alt="CueForGood" /></span>
                        <img src={Why_cb} alt="Why choose CueForGood Desktop" className="img-responsive hide-mobi" />
                        <img src={Why_cb1} alt="Why choose CueForGood" className="img-responsive show-mobi" />
                     </div>
                  </div>
               </div>
            </div>

            <div className="service_weoffer">
               <div className="container">
                  <h2 className="heading-cb">Services we offer</h2>
                  <h6>
                     Empowering the altruists to have a greater impact by leveraging
                     digital & eCommerce
                           </h6>
                  <p>
                     As an eCommerce Agency, we offer the following areas of expertise.
                           </p>

                  <div className="services-inn-cb">
                     <div className="left-service-cb">
                        <img src={Cfg_logo} alt="CueForGood Logo" className="img-responsive" />
                     </div>
                     <div className="right-service-cb">
                        <ul>
                           <li>Shopify</li>
                           <li>Magento</li>
                           <li>Woo/WordPress</li>
                           <li>Core PHP</li>
                           <li>Creative Design</li>
                           <li className="full-li">Organic Search/SEO</li>
                           <li>Paid Advertising</li>
                           <li>Content Services</li>
                           <li className="full-li">Conversion Rate Optimization</li>
                           <li>Email Marketing</li>
                           <li>Analytics</li>
                        </ul>
                        <div className="contactus-area btm-cont">
                           <div className="left-cont">
                              <h2 className="heading-cb">Please share details of your story & project
                                             requirements</h2>
                           </div>
                           <div className="right-cont">
                              <Link to="/contact/" className="contactus-btn">Contact us</Link>

                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>

            <div className="service_weoffer our_clients">
               <div className="container">
                  <h2 className="heading-cb">Our Clients</h2>
                  <h6> Good Company we keep</h6>
                  <p> We proudly serve the following brands and causes:</p>

                  <div className="client hide-desktop-client ">
                     <div className="client-logos">
                        <div className="slider-nav">
                           <Slider {...Mobile_slider}>
                           <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://www.allthatgrows.in/" target="_blank"
                                       className="client-links">
                                       <img src={Feather_icon} alt="allthatgrows logo" />
                                    </a>
                                    <div className="logo-client client1"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Allthatgrows:</h4>
                                    <p>We are a company that brings you the best, most healthful, and nourishing seeds. Our experience of producing and supplying superior quality seeds spans over a decade.
</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://www.perennialcycle.com/" target="_blank"
                                       className="client-links">
                                       <img src={Feather_icon} alt="PerennialCycle logo" />
                                    </a>
                                    <div className="logo-client client2"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>PerennialCycle:</h4>
                                    <p>When something is perennial it is enduring, it is sustainable and it lives in an unending cycle. Perennial is the flora that surrounds us, which we depend on for our own sustainable existence</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://www.learningrx.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="learningrx logo" /></a>
                                    <div className="logo-client client3"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>LearningRx:</h4>
                                    <p>LearningRx is a one-on-one brain training center that pairs clients
                                    with brain trainers for challenging yet fun mental games that
                                                   help enhance cognitive skills.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://alzheimersspeaks.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="Alzheimers Speaks
                                       logo" /></a>
                                    <div className="logo-client client4"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Alzheimer’s Speaks:</h4>
                                    <p>Alzheimer's Speaks is a Minnesota-based advocacy group and media outlet making an international impact by providing education and support for those dealing with Alzheimer’s disease and other forms of dementia.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="http://al-anonalateenindia.org/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="al-anonalateenindia logo" /></a>
                                    <div className="logo-client client5"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Al-Anon Family Groups:</h4>
                                    <p>Al-Anon Family Groups is a fellowship of relatives and friends of
                                    alcoholics who believe their lives
                                    have been affected by someone else’s drinking. We help you overcome
                                    the challenges posed
                                                   by a loved one with a drinking problem.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://veganologie.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="Veganologie
                                       logo" /></a>
                                    <div className="logo-client client6"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Veganologie:</h4>
                                    <p>“Our brand integrity lies in it’s quality, design ethos, and story. We believe that classic, well-made fashion doesn’t need to come at the cost of animal lives."</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://bettyandbiddy.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="BettyandBiddy
                                       logo" /></a>
                                    <div className="logo-client client7"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>BettyandBiddy:</h4>
                                    <p>We are Betty and Biddy and we believe in jewelry as an expression of yourself. We believe you can style and edit your look with jewelry to show the world the best version of you.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://drbdentalsolutions.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="Dr. B Dental
                                       logo" /></a>
                                    <div className="logo-client client8"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Dr. B Dental:</h4>
                                    <p>Dr.B Dental Solution is revolutionizing denture and dental appliance care with superior & and unique solutions that combine modern science with time-tested natural remedies.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://yogasleep.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="YogaSleep
                                       logo" /></a>
                                    <div className="logo-client client9"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>YogaSleep:</h4>
                                    <p>Our company has grown in size over the decades, and that same find-an-answer, inventor-ish, gee-whiz attitude is still around here, as is the love that started it all.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://zuladen.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="ZulaDen
                                       logo" /></a>
                                    <div className="logo-client client10"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>ZulaDen:</h4>
                                    <p>The Zula Den was created to hold space for our facilitators and their guests, our artists and their music, our teachers and their students
</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://goatswhey.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="GoatsWhey
                                       logo" /></a>
                                    <div className="logo-client client11"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>GoatsWhey:</h4>
                                    <p>To ensure the best quality whey protein, Goatswhey Inc. sources its goat whey protein concentrate from a carefully selected European supplier with over 100 years of experience in the dairy industry.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://madulf.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="Madulf
                                       logo" /></a>
                                    <div className="logo-client client12"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Madulf:</h4>
                                    <p>Our range of ready-to-cook products is 100% vegan, without any preservatives or additives, convenient to make, and tastes incredible – perfect for the conscious consumer looking for a more sustainable lifestyle.
</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://mydaolabs.com/" target="_blank"
                                       className="client-links">
                                       <img src={Feather_icon} alt="mydaolabs logo" />
                                    </a>
                                    <div className="logo-client client13"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>DAO Labs:</h4>
                                    <p>We deliver wellness through the wisdom of Chinese medicine and the
                                                   healing powers of our innovative products.</p>
                                 </div>
                              </div>
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://nirvanasage.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="nirvanasage logo" /></a>
                                    <div className="logo-client client14"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Nirvana Sage:</h4>
                                    <p>We are devoted to awakening the benefits of our traditional ancient systems of Yoga &amp; Tantra in the minds of the modern populace so as to build a healthy, peace filled,
                        joy loving society where we, respecting everyone's faith, religion, culture, make this whole world a beautiful place to live in.</p>
                                 </div>
                              </div>
                             
                              <div className="logo-outerr">
                                 <div className="log-inn">
                                    <a href="https://www.peeduspeople.com/" target="_blank"
                                       className="client-links"> <img src={Feather_icon} alt="Peedus people logo" /></a>
                                    <div className="logo-client client15"></div>
                                 </div>
                                 <div className="content-client ">
                                    <h4>Peedu's People:</h4>
                                    <p>Global initiative to offer love and kindness to our forsaken furry
                                    friends.
                                    Peedu's People is a group of dedicated people offering kindness and
                                    love to the animals in
                                    need all over the world. We fight for animal rights, hoping to be a
                                    voice for the voiceless
                                                   and change the world...one animal at a time.</p>
                                 </div>
                              </div>
                           </Slider>
                        </div>
                     </div>

                  </div>

                  <div className="client show-mobi-client ">
                     <div className="client-logos">
                        <div className="slider-nav">
                           <Slider {...nav}>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://www.allthatgrows.in/" target="_blank"
                                    className="client-links">
                                    <img src={Feather_icon} alt="allthatgrows logo" />
                                 </a>
                                 <div className="logo-client client1"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://www.perennialcycle.com/" target="_blank"
                                    className="client-links">
                                    <img src={Feather_icon} alt="PerennialCycle logo" />
                                 </a>
                                 <div className="logo-client client2"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://www.learningrx.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="learningrx logo" /></a>
                                 <div className="logo-client client3"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://alzheimersspeaks.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="Alzheimers Speaks
                                    logo" /></a>
                                 <div className="logo-client client4"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="http://al-anonalateenindia.org/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="al-anonalateenindia logo" /></a>
                                 <div className="logo-client client5"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://veganologie.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="Veganologie
                                    logo" /></a>
                                 <div className="logo-client client6"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://bettyandbiddy.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="BettyandBiddy
                                    logo" /></a>
                                 <div className="logo-client client7"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://drbdentalsolutions.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="Dr. B Dental
                                    logo" /></a>
                                 <div className="logo-client client8"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://yogasleep.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="YogaSleep
                                    logo" /></a>
                                 <div className="logo-client client9"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://zuladen.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="ZulaDen
                                    logo" /></a>
                                 <div className="logo-client client10"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://goatswhey.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="GoatsWhey
                                    logo" /></a>
                                 <div className="logo-client client11"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://madulf.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="Madulf
                                    logo" /></a>
                                 <div className="logo-client client12"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://mydaolabs.com/" target="_blank"
                                    className="client-links">
                                    <img src={Feather_icon} alt="mydaolabs logo" />
                                 </a>
                                 <div className="logo-client client13"></div>
                              </div>
                           </div>
                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://nirvanasage.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="nirvanasage logo" /></a>
                                 <div className="logo-client client14"></div>
                              </div>
                           </div>

                           <div className="logo-outerr">
                              <div className="log-inn">
                                 <a href="https://www.peeduspeople.com/" target="_blank"
                                    className="client-links"> <img src={Feather_icon} alt="Peedus people logo" /></a>
                                 <div className="logo-client client15"></div>
                              </div>
                           </div>
                           </Slider>
                        </div>
                     </div>
                     <div className="client-says">
                        <div className="slider-for">
                           <Slider {...view}>
                           <div className="content-client ">
                                 <h4>ATG:</h4>
                                 <p>We are a company that brings you the best, most healthful, and nourishing seeds. Our experience of producing and supplying superior quality seeds spans over a decade.</p>
                              </div>
                              <div className="content-client ">
                                 <h4>PerennialCycle:</h4>
                                 <p> When something is perennial it is enduring, it is sustainable and it lives in an unending cycle. Perennial is the flora that surrounds us, which we depend on for our own sustainable existence </p>
                              </div>
                              <div className="content-client ">
                                 <h4>LearningRx:</h4>
                                 <p>LearningRx is a one-on-one brain training center that pairs clients
                                       with brain trainers for challenging yet fun mental games that
                                                   help enhance cognitive skills.</p>
                              </div>
                              <div className="content-client ">
                                 <h4>Alzheimer’s Speaks:</h4>
                                 <p>Alzheimer's Speaks is a Minnesota-based advocacy group and media outlet making an international impact by providing education and support for those dealing with Alzheimer’s disease and other forms of dementia.</p>
                              </div>
                              <div className="content-client ">
                                 <h4>Al-Anon Family Groups:</h4>
                                 <p>Al-Anon Family Groups is a fellowship of relatives and friends of
                                       alcoholics who believe their lives
                                       have been affected by someone else’s drinking. We help you overcome
                                       the challenges posed
                                                   by a loved one with a drinking problem.</p>
                              </div>
                              <div className="content-client ">
                                 <h4>Veganologie</h4>
                                 <p>“Our brand integrity lies in it’s quality, design ethos, and story. We believe that classic, well-made fashion doesn’t need to come at the cost of animal lives."
</p>
                              </div>
                              <div className="content-client ">
                                 <h4>BettyandBiddy</h4>
                                 <p>We are Betty and Biddy and we believe in jewelry as an expression of yourself. We believe you can style and edit your look with jewelry to show the world the best version of you.
</p>
                              </div>
                              <div className="content-client ">
                                 <h4>Dr. B Dental</h4>
                                 <p>Dr.B Dental Solution is revolutionizing denture and dental appliance care with superior & and unique solutions that combine modern science with time-tested natural remedies.
</p>
                              </div>
                              <div className="content-client ">
                                 <h4>YogaSleep</h4>
                                 <p>Our company has grown in size over the decades, and that same find-an-answer, inventor-ish, gee-whiz attitude is still around here, as is the love that started it all.
</p>
                              </div>
                              <div className="content-client ">
                                 <h4>ZulaDen</h4>
                                 <p>The Zula Den was created to hold space for our facilitators and their guests, our artists and their music, our teachers and their students
</p>
                              </div>

                              <div className="content-client ">
                                 <h4>GoatsWhey</h4>
                                 <p>To ensure the best quality whey protein, Goatswhey Inc. sources its goat whey protein concentrate from a carefully selected European supplier with over 100 years of experience in the dairy industry.
</p>
                              </div>
                              <div className="content-client ">
                                 <h4>Madulf</h4>
                                 <p>Our range of ready-to-cook products is 100% vegan, without any preservatives or additives, convenient to make, and tastes incredible – perfect for the conscious consumer looking for a more sustainable lifestyle.
</p>
                              </div>

                              <div className="content-client ">
                                 <h4>DAO Labs:</h4>
                                 <p>We deliver wellness through the wisdom of Chinese medicine and the
                                                   healing powers of our innovative products.</p>
                              </div>
                              <div className="content-client ">
                                 <h4>Nirvana Sage:</h4>
                                 <p>We are devoted to awakening the benefits of our traditional ancient systems of Yoga &amp; Tantra in the minds of the modern populace so as to build a healthy, peace filled,
                        joy loving society where we, respecting everyone's faith, religion, culture, make this whole world a beautiful place to live in.</p>
                              </div>
                              <div className="content-client ">
                                 <h4>Peedu's People:</h4>
                                 <p>Global initiative to offer love and kindness to our forsaken furry
                                       friends.
                                       Peedu's People is a group of dedicated people offering kindness and
                                       love to the animals in
                                       need all over the world. We fight for animal rights, hoping to be a
                                       voice for the voiceless
                                                   and change the world...one animal at a time.</p>
                              </div>
                           </Slider>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="service_weoffer cb_testimonails">
               <div className="container">
                  <h2 className="heading-cb">Client Testimonials</h2>
                  <h6>Some good words</h6>
                  <div className="testimonials-section">
                     <div className="testi-inn-block">
                        <div className="wd-50 test-single">

                           <div className="test-block test2">
                              <figure>
                                 <img src={Atg_logo} alt="All That Grows Logo" />

                              </figure>
                              <div className="content-testi">
                                 <p>
                                    “The website design is phenomenal, and customers
                                    constantly rave about the packaging and design. Customers
                                    also like the functionality, which is something that’s
                                    helped set our business apart from competitors. Their
                                    company is value driven, and their employees and customers
                                    come first. I’m very happy with them. Any customer of
                                    theirs shouldn’t have any trouble. In terms of our PPC and
                                    comparison shopping campaigns, they’re doing exceptionally
                                    well. We’re happy with where we’re at with them. We’re
                                    receiving the same high quality deliverables, but at lower
                                    rates than those offered in Silicon Valley.”
                                             </p>
                              </div>
                              <h6>
                                 <a href="https://www.allthatgrows.in/"
                                    target="_blank">AllThatGrows.in</a>
                              </h6>
                           </div>
                           <div className="test-block test1">
                              <figure>
                                 <img src={Learningrx} alt="Learningrx Logo" />
                              </figure>
                              <div className="content-testi">
                                 <p>
                                    “We have worked with CueForGood and have done probably hundreds of
                                    projects with them. We have been consistently impressed with their
                                    professionalism, reliability, quality of work and extensive
                                    expertise in a wide span of technologies and services. LearningRx is
                                    a
                                    Franchise system with more than 70 Franchised locations, and
                                    CueForGood has helped us meet the many demands and challenges of
                                    building a technology
                                                   infrastructure to support multiple locations.<br /><br />
                                    As we bring new ideas and technology demands to the table, they are
                                    consistently able to take on new challenges with ease and expertise.
                                    They consistently
                                    deliver work on-time, on-budget and with the highest levels of
                                    quality.
                                                   <br /><br />
                                    In addition, CueForGood is a pleasure to work with as a team—they are
                                    friendly, easy to communicate with, highly professional and have the
                                    highest level of
                                    work ethics and values. I highly recommend CueForGood!.”
                                             </p>
                              </div>
                              <h6>
                                 <a href="https://www.learningrx.com/" target="_blank">LearningRx.com</a>
                              </h6>
                           </div>
                        </div>

                        <div className="wd-50 test-single">
                           <div className="test-block test1">
                              <figure>
                                 <img src={Badabon_logo} alt="Badabon Logo" />
                              </figure>
                              <div className="content-testi">
                                 <p>
                                    “I found their pure professionalism and passion for doing
                                    what they believe is best for the client to be most
                                    impressive. They went above and beyond what we initially
                                    thought they’d be doing. I’ve never seen this level of
                                    rofessionalism in other developers I’ve worked with. That
                                    was very impressive, and I’d recommend them to everyone
                                    just for this.”
                                             </p>
                              </div>
                              <h6>
                                 <a href="https://badabonharvest.bio/" className="link_exp"
                                    target="_blank">BadabonHarvest.bio</a>
                              </h6>
                           </div>
                           <div className="test-block test3">
                              <figure>
                                 <img src={Ansel_logo} alt="Ansel and Ivy Logo" />
                              </figure>
                              <div className="content-testi">
                                 <p>
                                    “Their technical competence is strong. If you present them
                                    with a broad or specific idea around a feature that you
                                    want to be developed, they have a keen ability to go and
                                    find solutions to that problem. That was refreshing for us
                                    because we didn’t have the time to go and show them how a
                                    feature should be implemented. Their team was able to
                                    execute solutions based on our ideas. Overall, we’re happy
                                    with what CueForGood did. We asked them to build a complex
                                    e-commerce platform with a lot of rules. They delivered a
                                    product that was not only to specification but also went
                                    above and beyond.”
                                             </p>
                              </div>
                              <h6>
                                 <a href="https://anselandivy.com/" class="link_exp" target="_blank">AnselAndIvy.com</a>
                              </h6>
                           </div>
                           <div className="test-block test4">
                              <figure>
                                 <img src={Dao_logo} alt="MyDaoLabs Logo" />
                              </figure>
                              <div className="content-testi">
                                 <p>
                                    “They’re a great group to work with for precision, speed,
                                    and affordability. They are a tremendous value for what
                                    they provide, and they’re typically pretty fast. That’s
                                    why we continue to work with them. Their CEO & founder was
                                    very passionate about our company and our product, and
                                    there was pretty strong chemistry from that perspective.
                                    He was very complimentary of the brand and demonstrated an
                                    incredible enthusiasm and motivation to help us achieve
                                    our goals.”
                                             </p>
                              </div>
                              <h6>
                                 <a href="https://mydaolabs.com/ " target="_blank">Mydaolabs.com</a>
                              </h6>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="contactus-area">
                     <div className="left-cont">
                        <h2 className="heading-cb">Make us part of the impact</h2>
                        <p>Please <a href="/contact/" className="a-simple">share
                                       details</a> of your story & project requirements</p>
                     </div>
                     <div className="right-cont">
                        <Link to="/contact/" className="contactus-btn">Contact us
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
            <div id="scroll-to" className="case-scroll">
               <a href="#" title="Scroll To Top">
                  <img src={Scroll_top_img} alt="Scroll To Top" />
               </a>
            </div>
         </div>

         <Clutch />
      </Layout>
   )
   }
}